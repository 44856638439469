import React from "react"
import { Container, Box, Grid, Typography } from "@material-ui/core";

import { makeStyles } from '@material-ui/core/styles';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import DescriptionIcon from '@material-ui/icons/Description';

import Layout from "../components/layout"
import SEO from "../components/seo"
import { PageTitle } from '../components/PageTitle'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
}));

export default function NAEPage(props) {
  const classes = useStyles();


  return (
    <Layout>
      <SEO title="Pesquisa e Inovação" description="Página de pesquisa e inovação da Faculdade Jardins." />
      <Container style={{ marginTop: 50 }}>
        <PageTitle>Pesquisa e Inovação</PageTitle>
        <Grid container style={{ marginTop: 70 }}>
          <Grid sm={12}>
            <p>A Faculdade Jardins com seu corpo docente altamente qualificado de pesquisadores, sempre desenvolveu parcerias e pesquisa em conjunto com outras grandes universidades, como por exemplo o projeto Giant junto ao núcleo de pesquisa da USP com foco em desenvolvimento de pesquisa na área de segurança, automação de processo, dentre outros, com realizações de Hackathon, além da presença de nossos pesquisadores na Escola Politécnica da USP, assim como recebemos professores pesquisadores da USP em nossa IES.</p>
            <p>Registramos que os primeiros passos para desenvolver a jornada entre a pesquisa e inovação, da teoria para a prática, ocorreu em 2016 quando a Faculdade Jardins foi aprovada como parceira para implementar a Educação Empreendedora via SEBRAE no Estado de Sergipe. Desde então muitos de nossos alunos desenvolveram pesquisa com foco em aplicação prática e desenvolvimento de novos negócios disruptivos. Como por exemplo, a startup Saber em Rede, que foi uma de nossas primeiras startups gerada por este núcleo de pesquisa e inovação, com mentorias entre professores e alunos, e como apoio da Faculdade Jardins desenvolveu a tecnologia e rodou o MVP na própria Jardins, tornando-se  hoje uma empresa S.A. que recebeu grandes investimentos e neste ano de 2023 foi destaque entre as empresas que mais crescem no Brasil pelo Ranking da Exame 2023, além de premiada pela 100 Open Top como uma  das melhores startups do Brasil.</p>
            <p>Em 2022 outro case de sucesso foi o surgimento da startup Dr.Mep, desenvolvida por duas alunas da graduação e nosso Núcleo de Pesquisa para criar a tecnologia e um negócio inovador na área de telemedicina veterinária. A Dr. Mep foi destaque Nacional em 2023 ao ser selecionada e investida na oitava temporada do Shark Tank Brasil.</p>
            <p>A Faculdade Jardins, através de seu Núcleo de Pesquisa e Inovação, juntamente com o empreendedorismo impulsionado pela X5 Business, se unem à FCJ, maior rede de Venture Builder da América Latina, dessa união nasce o projeto na Vertical de Universidade Garagem a X5 Ventures.</p>
            <p>A X5 Ventures tem como missão a integração de startups voltadas para o mercado ESG e Deeptechs no ecossistema de inovação aberta. Seu objetivo primordial é impulsionar um crescimento exponencial por meio de soluções que abordam os desafios pertencentes a cada setor de atuação, resultando em um impacto positivo significativo na sociedade. </p>
            <p>A metodologia da Universidade Garagem implementada tem como foco estreitar os laços entre academia, startups, empresas, comunidade e investidores. Promovendo um fortalecimento de todo ecossistema na nova economia.</p>
            <p>Startup Saber em Rede: <a href="https://saberemrede.net/" target="_blank">https://saberemrede.net/</a></p>
            <p>Startup Dr.MEP: <a href="https://app.drmep.com/" target="_blank">https://app.drmep.com/</a></p>
            <p>X5 Ventures: <a href="https://x5ventures.com.br/" target="_blank">https://x5ventures.com.br/</a></p>
            <p>FCJ Academy: <a href="http://fcjacademy.educacao.ws/" target="_blank">http://fcjacademy.educacao.ws/</a></p>
          </Grid>
        </Grid>
      </Container>
    </Layout>
  )
}
